import React, { useState } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import { Link, navigate } from "gatsby"
import CtaButton from "../components/cta-button/cta-button"
import "./dead-ends.scss"
import Note from "../components/note/note"
import { ServiceCard } from "../components/service-card/service-card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFax,
  faPhoneSquareAlt,
  faFileArchive,
  faFileInvoice,
  faFile,
} from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

import Fade from "react-reveal/Fade"
import { SloganFlipper } from "../components/slogan/slogan-flipper"
import { GlobalStateProvider } from "../utils/globalStateProvider"
import { FullScreenModal } from "../components/full-screen-modal/full-screen-modal"

export default function NegligenciaSoloInformacion() {
  const [status, setStatus] = useState("")

  return (
    <GlobalStateProvider>
      <SEO title="Solo informacion" />
      <Layout pageId="deadend-only-info">
        <div className="deadend-container">
          <h1>Resultado del cuestionario</h1>
          <h2>Enhorabuena!! Has llegado al final</h2>
          <p>
            No te preocupes, te entendemos perfectamente, cuando lo tengas claro
            cuenta con nosotros y contrata uno de estos servicios
          </p>
          <center className="button-container">
            <Fade left duration={2000}>
              <ul className="cards grid grid--desktop-2cols grid--smartphone-1cols">
                <li key="consulta-experto">
                  <ServiceCard
                    title="Te llamamos 30"
                    key="consulta-experto"
                    featuredImage={
                      <FontAwesomeIcon
                        icon={faWhatsapp}
                        color="#fcc831"
                        size="2x"
                      />
                    }
                    featureList={[
                      "Servicio <strong>premium</strong>",
                      "Un <strong>experto</strong> te llamará (tfno/whatsapp)",
                      // "<strong>30 minutos</strong> por <strong class='price'>30€</strong>",
                    ]}
                    ctaLink="/consulta-experto"
                  />
                </li>
                <li key="contratar-dictamen">
                  <ServiceCard
                    title="Dictamen"
                    key="contratar-dictamen"
                    featuredImage={
                      <FontAwesomeIcon
                        icon={faFileArchive}
                        color="#fcc831"
                        size="2x"
                      />
                    }
                    featureList={[
                      "Documento <strong>premium</strong>",
                      "<strong>Validez jurídica</strong>",
                      // "<strong class='price'>30€</strong> (consulta) + <strong class='price'>140€</strong>",
                    ]}
                    ctaLink="/encargo-dictamen"
                  />
                </li>
              </ul>
            </Fade>
          </center>

          <Fade right duration={3000}>
            <Note>
              Mientras te lo piensas te puede ayudar a decidirte{" "}
              <Link to="/situacion-actual-reclamaciones-contra-abogados/">
                este post
              </Link>
            </Note>
            <br></br>

            <Note>
              Si consideras que hay que corregir algo en las respuestas del
              cuestionario de autovaloración,{" "}
              <Link to="/cuestionario-autovaloracion/">
                puedes probar de nuevo
              </Link>
            </Note>
          </Fade>
        </div>
        <SloganFlipper></SloganFlipper>
        <FullScreenModal />
      </Layout>
    </GlobalStateProvider>
  )
}
